<template>
  <div>
    <barra-crud :botaoNovo="true" :onNovo="onNovo" :onFiltrar="filtrar" />

    <fidelizacao-table
      :fidelizacoes="fidelizacoes"
      @editar="editar($event)"
      @novo="onNovo"
      @excluir="excluir($event)"
    />

    <b-modal id="modalFidelizacaoForm" no-fade content-class="modalDireito">
      <template #modal-title>
        <img src="../../assets/img/icones/menu/loyalty_dark_40px.png" alt="" />
        Cadastrar / Editar Fidelização
      </template>
      <fidelizacao-form
        ref="FidelizacaoForm"
        :fidelizacao_edit="fidelizacao_edit"
        @salvou="onSalvou"
      />
      <template #modal-footer="{close}">
        <b-btn
          variant="primary"
          @click="salvar"
          class="mr-3"
          :disabled="loading"
        >
          <i class="fas fa-save" v-if="!loading"></i> Salvar</b-btn
        >
        <b-btn variant="warning" @click="close()"> <i class="fas fa-ban" ></i> Cancelar </b-btn>
      </template>
    </b-modal>
  </div>
</template>

<script>
import BarraCrud from "../../components/common/BarraCrud.vue";
import FidelizacaoForm from "../../components/Fidelizacao/FidelizacaoForm.vue";
import FidelizacaoTable from "../../components/Fidelizacao/FidelizacaoTable.vue";
import FidelizacoesLib from "../../libs/FidelizacoesLib";

export default {
  components: {
    BarraCrud,
    FidelizacaoForm,
    FidelizacaoTable,
  },
  data() {
    return {
      filtro: "",
      fidelizacoes: [],
      loading: false,
      fidelizacao_edit: {},
    };
  },
  methods: {
    filtrar(filtro) {
      this.filtro = filtro;
    },
    onNovo() {
      this.fidelizacao_edit = {};
      this.$bvModal.show("modalFidelizacaoForm");
    },
    async onSalvou() {
      this.carregar();

      this.$bvModal.hide("modalFidelizacaoForm");
    },
    editar(fidelizacao) {
      this.fidelizacao_edit = Object.assign({}, fidelizacao);
      this.$bvModal.show("modalFidelizacaoForm");
    },
    async excluir(id) {
      // console.log("id que vai excluir", id);
      this.toastConfirmacao(
        async () => {
          try {
            let result = await FidelizacoesLib.del(id);
            if (result) {
              this.showToast("success", "Fidelização excluída com sucesso!");
              this.carregar();
            }
          } catch (error) {
            this.showToast("error", error.message);
          }
        },
        () => {}
      );
    },
    async salvar() {
      this.loading = true;
      await this.$refs.FidelizacaoForm.salvar();
      this.loading = false;
    },
    async carregar() {
      try {
        this.fidelizacoes = await FidelizacoesLib.get();
      } catch (error) {
        this.showToast("error", "Erro ao carregar as fidelizaçoes!");
      }
    },
  },
  mounted() {
    this.carregar();
  },
};
</script>

<style></style>
