<template>
  <div>
    <b-table show-empty hover striped :fields="campos" :items="fidelizacoes">
     <template #empty>
        <div>
       <i class="fas fa-capsules"></i> Nenhum modelo de fidelização cadastrada, clique em <b-btn @click="$emit('novo')" variant="primary" size="sm" class="mx-2"> <i class="fas fa-plus"></i> Fidelização</b-btn> para cadastrar. 
      </div>
     </template>
      <template #cell(actions)="row">
        <b-btn variant="warning" @click="editar(row.item)" class="mr-3">
          <i class="fas fa-edit"></i> Editar</b-btn
        >
        <b-btn variant="danger" @click="excluir(row.item.id)">
          <i class="fas fa-trash"></i> Excluir</b-btn
        >
      </template>
      <template #cell(status)="row">
        <b-form-checkbox
          switch
          :value="1"
          :unchecked-value="0"
          v-model="row.item.status"
          >{{ row.item.status ? "Ativo" : "Inativo" }}</b-form-checkbox
        >
      </template>
      <template #cell(renovavel)="row">
        <b-form-checkbox
          disabled
          switch
          :value="1"
          :unchecked-value="0"
          :checked="row.item.renovavel "
          >{{ row.item.renovavel ? "Ativo" : "Inativo" }}</b-form-checkbox
        >
      </template>
      <template #cell(dependente_adicional)="row">
        <b-form-checkbox
          disabled
          switch
          :value="1"
          :unchecked-value="0"
          :checked="row.item.dependente_adicional "
          >{{ row.item.dependente_adicional ? "Ativo" : "Inativo" }}</b-form-checkbox
        >
      </template>
    </b-table>
  </div>
</template>

<script>
export default {
  props: {
    fidelizacoes: { type: Array, default: () => [] },
  },
  data() {
    return {
      campos: [
        {
          key: "nome",
          label: "Nome",
        },
        {
          key: "prazo",
          label: "Prazo",
        },
        {
          key: "qtd_dependentes",
          label: "Quantidade de Depentes",
        },
        {
          key: "status",
          label: "Status",
        },
        {
          key: "renovavel",
          label: "Renovavel",
        },
        {
          key: "dependente_adicional",
          // label: "dep",
        },
        {
          key: "actions",
          label: "",
          class: "text-rigth",
        },
      ],
    };
  },
  methods: {
    editar(fidelizacao) {
      this.$emit("editar", Object.assign({}, fidelizacao));
    },
    excluir(id) {
      this.$emit("excluir", id);
    },
  },
};
</script>

<style></style>
