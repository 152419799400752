<template>
  <div>
    <div class="row">
      <div class="col-12  col-sm-10">
        <b-form-group>
          <label for="">Nome</label>
          <b-form-input
            v-model="fidelizacao.nome"
            :state="validateState({ campo: 'nome' })"
            placeholder="Nome"
          ></b-form-input>
        </b-form-group>
      </div>
      <div class="col-12  col-sm-2">
        <b-form-group>
          <label for="">Status</label>
          <b-form-checkbox
            v-model="fidelizacao.status"
            :unchecked-value="0"
            :value="1"
            switch
            class="form-control text-center"
            >{{ fidelizacao.status ? "Ativo" : "Inativo" }}</b-form-checkbox
          >
        </b-form-group>
      </div>
    </div>
    <div class="row">
      <div class="col-9">
        <div class="row">
          <div class="col-12  col-sm-3">
            <b-form-group>
              <label>Prazo (Meses)</label>
              <b-input
                v-model="fidelizacao.prazo"
                :state="validateState({ campo: 'prazo' })"
                type="number"
              ></b-input>
              <!-- <span>Finaliza em: {{ finalizaEm }}</span> -->
            </b-form-group>
          </div>
          <div class="col-12  col-sm-3">
            <b-form-group>
              <label>Dependentes</label>
              <b-input
                v-model="fidelizacao.qtd_dependentes"
                :state="validateState({ campo: 'qtd_dependentes' })"
                type="number"
              ></b-input>
            </b-form-group>
          </div>
           <div class="col-12  col-sm-6">
            <b-form-group>
              <label>Tipo de Pessoa</label>
              <v-select v-model="fidelizacao.tipo_pessoa" multiple :options="['Médico','Laboratório','Funcionário','Paciente']" />
            </b-form-group>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="row">
          <div class="col-12 col-sm-6 pr-0">
            <b-form-group>
              <label>Renovavel</label>
              <b-check
                switch
                v-model="fidelizacao.renovavel"
                @change="fidelizacao.valor_renovacao = 0.0"
                :unchecked-value="0"
                :value="1"
                class="form-control text-center"
              >
                {{ fidelizacao.renovavel ? "Sim" : "Nâo" }}</b-check
              >
            </b-form-group>
          </div>
          <div class="col-12 col-sm-6 pl-1">
            <b-form-group>
              <label>Valor </label>
              <!-- <b-input switch class="form-control text-center"> Sim</b-input> -->

              <money
                :readonly="!fidelizacao.renovavel"
                :class="[
                  'form-control text-center',

                  !validateState({ campo: 'valor_renovacao' }) &&
                  typeof validateState({ campo: 'valor_renovacao' }) ==
                    'boolean'
                    ? ' isInvalid'
                    : 'isValid',
                ]"
                :state="validateState({ campo: 'valor_renovacao' })"
                v-model="fidelizacao.valor_renovacao"
                v-bind="money"
              ></money>
            </b-form-group>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-sm-4">
        <b-form-group>
          <label for="">Produto</label>
          <select-exames
            :selecionado="fidelizacao.produto_id"
            @input="produtoChanged"
            :showBotaoAdd="false"
            :showBotaoEdit="false"
          ></select-exames>
        </b-form-group>
      </div>
      <div class="col-12 col-sm-2">
        <b-form-group>
          <label>Valor Venda</label>
          <money
            class="form-control"
            v-bind="money"
            v-model="fidelizacao.valor_venda"
          ></money>
        </b-form-group>
      </div>
      <div class="col-12 col-sm-2">
        <b-form-group>
          <label>Valor Custo</label>
          <money
            class="form-control"
            v-bind="money"
            v-model="fidelizacao.valor_custo"
          ></money>
        </b-form-group>
      </div>

      <div class="col-12 col-sm-2">
        <b-form-group>
          <label>Dependente Adicional</label>
          <b-check
            switch
            v-model="fidelizacao.dependente_adicional"
            @change="fidelizacao.valor_adicional = 0.0"
            :unchecked-value="0"
            :value="1"
            class="form-control text-center"
          >
            {{ fidelizacao.dependente_adicional ? "Sim" : "Nâo" }}</b-check
          >
        </b-form-group>
      </div>
      <div class="col-12 col-sm-2">
        <b-form-group>
          <label>Valor</label>
          <!-- <b-input switch class="form-control text-center"> Sim</b-input> -->

          <money
            :readonly="!fidelizacao.dependente_adicional"
            :class="[
              'form-control text-center',

              !validateState({ campo: 'valor_adicional' }) &&
              typeof validateState({ campo: 'valor_adicional' }) == 'boolean'
                ? ' isInvalid'
                : 'isValid',
            ]"
            :state="validateState({ campo: 'valor_adicional' })"
            v-model="fidelizacao.valor_adicional"
            v-bind="money"
          ></money>
        </b-form-group>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <b-table
          outlined
          small
          stacked="sm"
          hover
          :fields="campos"
          :items="fidelizacao.desconto"
        >
          <template #cell(tipo_venda)="{item, index}">
            <b-select
              :options="tipoVenda"
              v-model="item.tipo_venda"
              size="sm"
              :state="validateState({ campo: 'tipo_venda', index })"
            ></b-select>
          </template>
          <template #cell(desconto_tipo)="{item, index}">
            <b-select
              :options="tipoDesconto"
              v-model="item.desconto_tipo"
              size="sm"
              :state="validateState({ campo: 'desconto_tipo', index })"
            ></b-select>
          </template>
          <template #cell(desconto_valor)="{item, index}">
            <money
              :class="[
                'form-control form-control-sm text-center',
                {
                  isInvalid:
                    !validateState({ campo: 'desconto_valor', index }) &&
                    typeof validateState({ campo: 'desconto_valor', index }) ==
                      'boolean',
                },
              ]"
              v-model="item.desconto_valor"
              v-bind="item.desconto_tipo == 'V' ? money : pct"
              :state="validateState({ campo: 'desconto_valor', index })"
            ></money>
          </template>
          <template #cell(actions)="row">
            <b-btn
              @click="removeDesconto(row.index)"
              variant="transparent"
              size="sm"
            >
              <i class="fas fa-times"></i>
            </b-btn>
          </template>
        </b-table>
      </div>
    </div>
    <div class="row ">
      <div class="col mb-3 mt-n3">
        <b-btn class="float-right" @click="addDesconto" variant="primary">
          <i class="fas fa-plus"></i>
        </b-btn>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <h5>Contrato</h5>
        <ckeditor
          ref="ckeditor"
          v-model="fidelizacao.contrato"
          :config="editorConfig"
        />
      </div>
    </div>
    <small class="text-muted">
      * Clique e arraste as variaveis para o contrato no local desejado
    </small>
    <div class="d-flex">
      <div
        class="m-2 d-flex flex-rows"
        style="cursor: pointer;"
        v-for="variable in variables"
        :key="variable"
      >
        <div class="border rounded p-1  user-select-all">{{ variable }}</div>
      </div>
    </div>
  </div>
</template>

<script>
//import moment from "moment";
import Validadores from "../../helpers/Validadores";
import FidelizacoesLib from "../../libs/FidelizacoesLib";
import ExamesLib from "../../libs/ExamesLib";
import CKEditor from "ckeditor4-vue";
import SelectExames from "../common/SelectExames.vue";

export default {
  components: {
    ckeditor: CKEditor.component,
    SelectExames,
  },
  props: {
    fidelizacao_edit: Object,
  },
  data() {
    return {
      produtos: [],
      campos: [
        { key: "tipo_venda", label: "Tipo de Venda", class: " text-center" },
        {
          key: "desconto_tipo",
          label: "Tipo de Desconto",
          class: " text-center",
        },
        { key: "desconto_valor", label: "Valor", class: " text-center" },
        { key: "actions", label: "", class: " text-center" },
      ],
      variables: [
        "{{codigo_cartao}}",
        "{{qtd_dependentes}}",
        "{{validade_meses}}",
        "{{dt_aquisicao}}",
        "{{dt_vencimento}}",
        "{{titular}}",
        "{{cpf_titular}}",
        "{{dependentes}}",
      ],
      fidelizacao: {
        nome: "",
        prazo: null,
        qtd_dependentes: null,
        status: 1,
        renovavel: 0,
        valor_renovacao: 0.0,
        dependente_adicional: 0,
        valor_adicional: 0.0,
        produto_id: null,
        desconto: [
          {
            tipo_venda: "",
            desconto_tipo: "V",
            desconto_valor: 0.0,
          },
        ],
        valor_venda: 0.0,
        valor_custo: 0.0,
        contrato: "",
        tipo_pessoa:"",
      },
      editorConfig: {
        extraPlugins: "div, font, justify",
      },
      tipoVenda: ["Consulta", "Exame", "Produto"],
      tipoDesconto: [
        { value: "V", text: "Valor" },
        { value: "P", text: "Porcentagem" },
      ],
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "R$ ",

        precision: 2,
        masked: false,
      },
      pct: {
        prefix: "% ",
        precision: 2,
        decimal: ",",
        masked: false,
      },
      hasErrors: [],
      loading: false,
    };
  },
  methods: {
    addDesconto() {
      this.fidelizacao.desconto.push({
        tipo_venda: "",
        desconto_tipo: "V",
        desconto_valor: 0.0,
      });
    },
    removeDesconto(index) {
      this.fidelizacao.desconto.splice(index, 1);
    },
    // onReady(editor) {
    //   // Insert the toolbar before the editable area.
    //   console.log(editor);
    //    const insertPosition = editor.document
    //    console.log(insertPosition);
    //   editor.insertText('dasdsdasdasdasdasdas', null, 100)
    // },
    async onSelectVariable(variable) {
      if (this.variables.includes(variable)) {
        // const ckeditor = this.$refs.ckeditor;
        try {
          await navigator.clipboard.writeText(variable);

          this.showToast("success", "Copiado para área de transferência!");
        } catch (error) {
          console.log(error);
        }
      }
    },
    validateState(campo) {
      // console.log('validateState',campo)
      let hasError = this.hasErrors.find(
        (x) => x.campo.campo == campo.campo && x.campo.index == campo.index
      );
      //console.log('hasErrors', this.hasErrors)
      // console.log('hasErrir', hasError)
      if (!hasError) return null;
      else return hasError.state;
    },
    addOrUpdateError(campo, state) {
      // console.log(campo, state);
      let hasError;
      if (campo.index && campo.index != "") {
        hasError = this.hasErrors.find(
          (x) => x.campo.campo == campo.campo && x.campo.index === campo.index
        );
      } else {
        hasError = this.hasErrors.find((x) => x.campo.campo == campo.campo);
      }

      // console.log("hasError que encontrou", hasError);
      if (!hasError) {
        this.hasErrors.push({ campo, state });
        //console.log(this.hasErrors)
      } else {
        hasError.state = state;
      }
      // console.log("hasErrors DEPOIS", this.hasErrors);
    },
    clearErrors() {
      this.hasErrors.map((error) => {
        error.state = null;
      });
    },
    produtoChanged(produto) {
      if (!produto) {
        this.showToast("error", "Produto com erro", 3500);
        return;
      }
      this.fidelizacao.valor_custo = this.fidelizacao.valor_custo?this.fidelizacao.valor_custo:produto.valor_custo;
      this.fidelizacao.valor_venda = this.fidelizacao.valor_venda?this.fidelizacao.valor_venda:produto.valor_venda;
      this.fidelizacao.produto_id = this.fidelizacao.produto_id?this.fidelizacao.produto_id:produto.id;
    },
    validar(index = "") {
      console.log(index);
      //   console.log("try validate", `index [${index}]`);
      //   console.log(this.anamnese.questionario[index]);
      this.clearErrors();
      this.$forceUpdate();

      let ignorar = 0;

      this.addOrUpdateError(
        { campo: "nome" },
        !Validadores.string.IsNullOrEmpty(this.fidelizacao.nome)
      );

      this.addOrUpdateError(
        { campo: "prazo" },
        !Validadores.string.IsNullOrEmpty(this.fidelizacao.prazo)
      );

      this.addOrUpdateError(
        { campo: "qtd_dependentes" },
        !Validadores.string.IsNullOrEmpty(this.fidelizacao.qtd_dependentes)
      );

      if (this.fidelizacao.renovavel) {
        // this.addOrUpdateError(
        //   { campo: "valor_renovacao" },
        //   !Validadores.string.IsNullOrEmpty(this.fidelizacao.valor_renovacao)
        // );
      } else {
        //this.addOrUpdateError({ campo: "valor_renovacao" }, null);
        //ignorar++;
      }

      if (this.fidelizacao.dependente_adicional) {
        this.addOrUpdateError(
          { campo: "valor_adicional" },
          !Validadores.string.IsNullOrEmpty(this.fidelizacao.valor_adicional)
        );
      } else {
        this.addOrUpdateError({ campo: "valor_adicional" }, null);
        ignorar++;
      }

      // this.addOrUpdateError(
      //   { campo: "tipo_venda", index },
      //   !Validadores.string.IsNullOrEmpty(
      //     this.fidelizacao.desconto[index].tipo_venda
      //   )
      // );
      // this.addOrUpdateError(
      //   { campo: "desconto_tipo", index },
      //   !Validadores.string.IsNullOrEmpty(
      //     this.fidelizacao.desconto[index].desconto_tipo
      //   )
      // );
      // this.addOrUpdateError(
      //   { campo: "desconto_valor", index },
      //   !Validadores.string.IsNullOrEmpty(
      //     this.fidelizacao.desconto[index].desconto_valor
      //   )
      // );

      // console.log(
      //   this.hasErrors.filter((x) => x.state == true).length,
      //   "ignorar",
      //   ignorar
      // );
      return (
        this.hasErrors.filter((x) => x.state == true).length >= 4 - ignorar
      );
    },
    async salvar() {
      let validou = this.fidelizacao.desconto
        .map((_, i) => {
          return this.validar(i);
        })
        .some((e) => e == false);

      if (validou) {
        this.showToast("error", "Por favor, verifique os dados.");
        return;
      }

      try {
        this.loading = true;
        let result = await FidelizacoesLib.store(this.fidelizacao);
        if (result.status == 200 || result.status == 201) {
          this.showToast("success", "Fidelização salva com sucesso!");
          this.$emit("salvou");
        }
      } catch (error) {
        this.showToast("error", error, 5000);
      } finally {
        this.loading = false;
      }
    },
    async carregaProduto() {
      try {
        this.produtos = await ExamesLib.get();
      } catch (error) {
        console.log("erro ao carregar produtos", error);
      }
    },
  },
  mounted() {
    this.carregaProduto();
    if (this.fidelizacao_edit && this.fidelizacao_edit.id) {
      this.fidelizacao = Object.assign({}, this.fidelizacao_edit);
    }
  },
  computed: {
    // finalizaEm() {
    //   return moment()
    //     .add(this.fidelizacao.prazo, "M")
    //     .format("YYYY/MM/DD");
    // },
  },
};
</script>

<style></style>
